<script>
export default {
  props: {
    storageItem: {
      type: Object,
      required: true
    }
  },
  computed: {
    fileCharacteristicFields() {
      const fields = [
        { key: 'file_format', label: 'File format' },
        { key: 'format_version', label: 'File format version' },
        { key: 'encoding', label: 'Encoding' },
        { key: 'csv_delimiter', label: 'CSV delimiter' },
        { key: 'csv_record_separator', label: 'CSV record separator' },
        { key: 'csv_quoting_char', label: 'CSV quoting character' },
        {
          key: 'csv_has_header',
          label: 'Has CSV header',
          formatter: (x) => (x ? this.$gettext('Yes') : this.$gettext('No'))
        }
      ]

      return fields.map(({ key, label, formatter }) => [
        key,
        this.$pgettext('File field', label),
        formatter || ((x) => (x || this.$gettext('-')))
      ])
    },
    metadataEntries() {
      const metadata = this.storageItem.metadata
      if (!metadata) {
        return []
      }

      const fileCharacteristics = JSON.parse(JSON.stringify(metadata.fileCharacteristics))
      fileCharacteristics.file_format = fileCharacteristics.file_format_version.file_format
      fileCharacteristics.format_version = fileCharacteristics.file_format_version.format_version
      if (fileCharacteristics.file_format !== 'csv') {
        delete fileCharacteristics.csv_delimiter
        delete fileCharacteristics.csv_record_separator
        delete fileCharacteristics.csv_quoting_char
        delete fileCharacteristics.csv_has_header
      }

      const byteSize = metadata.fileSize
      const humanSize = this.$options.filters.formatBytes(metadata.fileSize)

      const entries = [[this.$pgettext('File field', 'Identifier'), metadata.identifier]]

      entries.push(
        [
          this.$pgettext('File field', 'File size'),
          this.$gettextInterpolate(
            this.$ngettext(
              '%{ humanSize } (%{ byteSize } byte)',
              '%{ humanSize } (%{ byteSize } bytes)',
              byteSize
            ),
            { humanSize, byteSize }
          )
        ],
      )

      let useCategory
      switch (metadata.useCategory) {
        case 'http://uri.suomi.fi/codelist/fairdata/use_category/code/source':
          useCategory = this.$pgettext('Use category', 'Source material')
          break
        case 'http://uri.suomi.fi/codelist/fairdata/use_category/code/outcome':
          useCategory = this.$pgettext('Use category', 'Outcome material')
          break
        case 'http://uri.suomi.fi/codelist/fairdata/use_category/code/publication':
          useCategory = this.$pgettext('Use category', 'Publication')
          break
        case 'http://uri.suomi.fi/codelist/fairdata/use_category/code/documentation':
          useCategory = this.$pgettext('Use category', 'Documentation')
          break
        case 'http://uri.suomi.fi/codelist/fairdata/use_category/code/configuration':
          useCategory = this.$pgettext('Use category', 'Configuration files')
          break
        case 'http://uri.suomi.fi/codelist/fairdata/use_category/code/method':
          useCategory = this.$pgettext('Use category', 'Method')
          break
        case 'http://uri.suomi.fi/codelist/fairdata/use_category/code/rights':
          useCategory = this.$pgettext('Use category', 'Rights statement')
          break
        case null:
          useCategory = null
          break
        default:
          useCategory = this.$pgettext('Use category', 'Unknown use category')
      }
      entries.push([this.$pgettext('File field', 'Use category'), useCategory])

      entries.push([this.$pgettext('File field', 'Checksum'), metadata.checksum])
      for (const [field, name, convertFunc = String] of this.fileCharacteristicFields) {
        if (field in fileCharacteristics) {
          // Some fields (eg. booleans) are converted to a string using a custom
          // function. Convert to string directly if no function is provided.
          const value = convertFunc(fileCharacteristics[field])

          entries.push([name, value])
        }
      }

      return entries
    }
  }
}
</script>
<template>
  <div v-if="storageItem.metadataOpen" class="metadata-container">
    <dl v-if="metadataEntries.length > 0" class="metadata-list">
      <template v-for="[label, value] in metadataEntries">
        <dt :key="label">{{ label }}:</dt>
        <dd v-if="value" :key="`${label}-value`">{{ value }}</dd>
        <dd v-else :key="`${label}-value`">-</dd>
      </template>
    </dl>
    <div v-else class="text-center">
      <b-spinner type="grow" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '/src/assets/css/app';

.metadata-container {
  padding: 1rem 0.5rem;
  border: 1px solid app.$gray-light;
  border-top: none;
  margin-bottom: 0.5rem;
}
.metadata-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.75rem;
  line-height: 2rem;
  align-items: center;
}
.metadata-list > dt {
  font-weight: normal;
  text-align: right;
}
.metadata-list > dd {
  margin-bottom: 0;
  word-break: break-all;
}
</style>
